import Vue from 'vue'
import { VueComponentUpload as upload } from '@kvass/storage'

import Icon from '@kvass/vue2-icon'
Vue.component('FontAwesomeIcon', Icon)
Vue.component('Icon', Icon)

import { ButtonComponent, setup as ButtonSetup } from 'vue-elder-button'
ButtonSetup({
  type: 'button',
})
Vue.component('ButtonComponent', ButtonComponent)

import { setup as LoaderSetup, LoaderComponent as Loader } from 'vue-elder-loader'
LoaderSetup({
  theme: 'overlay',
  icon: { icon: ['fad', 'spinner-third'], size: '2x' },
  delay: 200,
})
Vue.component('Loader', Loader)

import { setup as FileSetup } from 'vue-elder-file'
FileSetup({
  upload,
})
