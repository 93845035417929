import isMobilePhone from 'validator/lib/isMobilePhone'
import { i18n } from '@/i18n'

export default Validator => {
  Validator.register(
    'mobile',
    function(value = '') {
      return isMobilePhone(value.replace(/\s/g, ''))
    },
    i18n.t('invalid', { x: ':attribute' }),
  )
}
