import graphql from '@/api/graphql'
import Queries from '@/api/graphql/queries'
import Mutations from '@/api/graphql/mutations'
import { Extractor } from '@/api/utils'

const Methods = {
  get: resource => (payload = {}, options = {}) => {
    let { query = payload.id ? resource : `${resource}s`, transform = Extractor } = options

    return graphql.request(Queries[query], payload).then(transform)
  },

  mutate: resource => (payload = {}, options = {}) => {
    let { id, ...data } = payload
    let {
      mutation = id ? `${resource}Update` : `${resource}Create`,
      transform = Extractor,
      transformPayload = true,
    } = options

    return graphql.request(Mutations[mutation], transformPayload ? { id, data } : payload).then(transform)
  },

  delete: resource => (payload = {}, options = {}) => {
    let { mutation = `${resource}Delete`, transform = Extractor } = options

    return graphql.request(Mutations[mutation], payload).then(transform)
  },
}

export { Methods }

export default function (resource, config = {}) {
  return {
    get: Methods.get(resource),
    mutate: Methods.mutate(resource),
    delete: Methods.delete(resource),
    ...config,
  }
}
