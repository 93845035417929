<template>
  <div id="app">
    <router-view />
    <TaskModal style="z-index: 1002" />
  </div>
</template>

<script>
import TaskModal from '@/components/TaskModal'

export default {
  components: {
    TaskModal,
  },
}
</script>
