import API from '@/api/Residential'
import MixinFactory from '@/store/mixin'
import { i18n } from '@/i18n'

const Mixin = MixinFactory()

const state = {
  ...Mixin.state,
}

const mutations = {
  ...Mixin.mutations,
}

const actions = {
  fetch: Mixin.actions.fetch(API.get),
}

const getters = {
  name: state => {
    if (!state.item.number) return
    return `${i18n.tc('residential')} ${state.item.number}`
  },
  isFixedSale: state => {
    if (!state.item.sale) return
    return state.item.sale.fixed
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
