import { i18n } from '@/i18n'
import onError from '@/components/vendors/vue-elder-loader/onError'
import { setup as ButtonSetup } from 'vue-elder-button'
import { setup as LoaderSetup } from 'vue-elder-loader'
import { setup as FileSetup } from 'vue-elder-file'

export default () => {
  ButtonSetup({
    labels: {
      loading: i18n.t('loading', { resource: undefined }) + '...',
      error: i18n.t('somethingWentWrong'),
      confirm: i18n.t('areYouSure'),
      success: i18n.t('completed'),
    },
  })

  LoaderSetup({
    messages: {
      loading: i18n.t('loading', { resource: undefined }) + '...',
      error: onError,
    },
  })

  FileSetup({
    dropMessage: i18n.t('dragFilesOrDrop', { extension: '' }),
  })
}
