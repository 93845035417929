import { i18n } from '@/i18n'

function BirthNumber(value) {
  if (typeof value !== 'string') value = value.toString()
  if (!value || value.length !== 11) return false

  let _sum = function(value, factors) {
    let sum = 0
    for (let i = 0, l = factors.length; i < l; ++i) {
      sum += parseInt(value.charAt(i), 10) * factors[i]
    }
    return sum
  }

  let checksum1 = 11 - (_sum(value, [3, 7, 6, 1, 8, 9, 4, 5, 2]) % 11)
  if (checksum1 === 11) checksum1 = 0
  let checksum2 = 11 - (_sum(value, [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]) % 11)
  if (checksum2 === 11) checksum2 = 0
  return checksum1 === parseInt(value.charAt(9), 10) && checksum2 === parseInt(value.charAt(10), 10)
}

export default Validator => {
  Validator.register(
    'socialSecurityNumber',
    function(value = '') {
      return BirthNumber(value.replace(/\s/g, ''))
    },
    i18n.t('invalid', { x: ':attribute' }),
  )
}
