import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import Loading from '@/views/Loading.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/bid/:id',
    name: 'Bid',
    props: true,
    component: () => LazyLoadView(import(/* webpackChunkName: "bid" */ '@/views/Bid/Component.vue')),
  },
  {
    path: '/purchase/:id',
    name: 'Purchase',
    props: route => ({ accessToken: route.query.accessToken, id: route.params.id }),
    component: () => LazyLoadView(import(/* webpackChunkName: "purchase" */ '@/views/Purchase/Component.vue')),
  },
  {
    path: '/custom/:id',
    name: 'Custom',
    props: route => ({ accessToken: route.query.accessToken, id: route.params.id }),
    component: () => LazyLoadView(import(/* webpackChunkName: "custom" */ '@/views/Custom/Component.vue')),
  },
  {
    path: '/account/:id',
    name: 'Account',
    props: route => ({ accessToken: route.query.accessToken, id: route.params.id }),
    component: () => LazyLoadView(import(/* webpackChunkName: "account" */ '@/views/Account/Component.vue')),
  },
  {
    path: '/shop/:id',
    name: 'Shop',
    props: route => ({ accessToken: route.query.accessToken, id: route.params.id }),
    component: () => LazyLoadView(import(/* webpackChunkName: "shop" */ '@/views/Shop/Component.vue')),
  },
  {
    path: '/404',
    name: 'notfound',
    component: () => LazyLoadView(import(/* webpackChunkName: "notfound" */ '@/views/404.vue')),
  },
  {
    path: '*',
    redirect: '/404',
  },
]

function LazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: Loading,
    delay: 150,
    // error: require('@views/_timeout.vue').default,
    // timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
