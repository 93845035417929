import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ComponentSetup from '@/components/setup'

import moment from 'moment/min/moment-with-locales'

Vue.use(VueI18n)
const defaultLanguage = document.documentElement.lang || 'nb'

moment.locale(defaultLanguage)

export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {
    [defaultLanguage]: require(`../i18n/${defaultLanguage}.json`),
  },
})

const loadedLanguages = [defaultLanguage]

export function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  window.sessionStorage.setItem('lang', lang)

  moment.locale(lang)
  ComponentSetup()
  return lang
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `../i18n/${lang}.json`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

export function mapi18n() {
  let scope = typeof arguments[0] === 'string' ? arguments[0] : undefined
  let value = arguments[scope ? 1 : 0]

  function resolveEntry(entry) {
    let key, args

    switch (entry.constructor) {
      case String:
        key = args = entry
        break
      case Array:
        ;[key, args] = entry
        break
      case Object:
        ;[key, args] = Object.entries(entry)[0]
        break
    }

    switch (args.constructor) {
      case Function:
        return [key, args.call(this)]
      case String:
        return [key, i18n.tc(args)]
      case Array:
        return [key, i18n.tc(...args)]
      default:
        return [key, i18n.tc(key, args)]
    }
  }

  function build() {
    let base = value instanceof Array ? value : Object.entries(value)
    return Object.fromEntries(base.map(resolveEntry.bind(this)))
  }

  if (!scope) return build()
  return {
    [scope]: build,
  }
}
