import { mergeDeepRight } from 'ramda'

const TransformPayload = (value = {}) => {
  if (!(value instanceof Array)) return [value, {}]
  return value.length === 1 ? [...value, {}] : value
}

export default function () {
  let state = {
    item: {},
    items: [],
    promises: {
      fetch: null,
      fetchOne: null,
      mutate: null,
      remove: null,
    },
  }

  let mutations = {
    set(state, payload) {
      return (state[payload instanceof Array ? 'items' : 'item'] = payload || {})
    },
    setPromise(state, { key, promise }) {
      key = key instanceof Array ? key : [key]
      key.forEach(k => (state.promises[k] = promise))
      return promise
    },
  }

  let actions = {
    fetch(action) {
      return function ({ commit }, payloadRaw) {
        let [payload, options] = TransformPayload(payloadRaw)

        let isOne = Boolean('id' in payload)
        if (options.resetBeforeAction !== false) commit('set', isOne ? {} : [])
        let promise = action(payload, options)
        if (options.setPromise !== false) commit('setPromise', { key: isOne ? 'fetchOne' : 'fetch', promise })

        return promise.then(data => {
          commit('set', data)
          return data
        })
      }
    },
    mutate(action) {
      return function ({ commit, state }, payloadRaw) {
        let [payload, options] = TransformPayload(payloadRaw)
        let promise = action(payload, options)
        if (options.setPromise !== false) commit('setPromise', { key: 'mutate', promise })

        return promise.then(data => {
          if (options.strategy === 'merge') data = mergeDeepRight(state.item, data)
          if (typeof options.strategy === 'function') data = options.strategy(state.item, data)
          commit('set', data)
          return data
        })
      }
    },
    remove(action) {
      return function ({ commit }, payloadRaw) {
        let [payload, options] = TransformPayload(payloadRaw)
        let promise = action(payload, options)
        if (options.setPromise !== false) commit('setPromise', { key: 'remove', promise })

        return promise
      }
    },
  }

  return { actions, mutations, state }
}
