const Clone = function() {
  return JSON.parse(JSON.stringify.apply(this, arguments))
}

const isObject = function(val) {
  return Object.prototype.toString.call(val) === '[object Object]'
}

function PartialUpdate(out) {
  out = out || {}
  let strict = typeof arguments[arguments.length - 1] === 'boolean' && arguments[arguments.length - 1]

  const canWrite = (key, target, value) => {
    if (!strict) return true
    if (!(key in target)) return false
    if (isObject(target[key]) && [null, undefined].includes(value[key])) return false

    return true
  }

  for (var i = 1, len = arguments.length; i < len; ++i) {
    var obj = arguments[i]

    if (!obj) {
      continue
    }

    for (var key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue
      }

      if (out[key] && isObject(obj[key])) {
        if (canWrite(key, out, obj)) out[key] = PartialUpdate(out[key], obj[key], strict)
        continue
      }

      if (canWrite(key, out, obj)) out[key] = obj[key]
    }
  }

  return out
}

function IconBinding(value) {
  if (!value) return
  if (value instanceof Array || typeof value === 'string') return { icon: value }
  return value
}

function setByPath(path, value, obj) {
  if (!obj) return

  let getValue = (v, s) => {
    if (typeof v === 'function') return v(s)
    return v
  }

  if (typeof path === 'string') path = path.split('.')
  let current = path.shift()

  // Handle wildcards
  if (path[0] === '*' && obj[current] instanceof Array) {
    path = path.slice(1) // Remove wildcard from path
    if (!path.length) return obj[current].map(entry => getValue(value, entry))

    return obj[current].map(entry => setByPath([...path], value, entry))
  }

  if (path.length) return setByPath(path, value, obj[current])
  return (obj[current] = getValue(value, obj[current]))
}

function getByPath(path, obj) {
  if (typeof path === 'string') path = path.split('.')
  let current = path.shift()

  return path.length ? getByPath(path, obj[current]) : obj[current]
}

function Debounce(func, wait, immediate) {
  let timeout
  return function() {
    let context = this,
      args = arguments
    let later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

function getDimensions(url) {
  return new Promise((resolve, reject) => {
    if (!url) return reject(new Error('Url is required'))
    let img = new Image()
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = reject
    img.src = url
  })
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function Capitalize(val = '') {
  return val.charAt(0).toUpperCase() + val.substring(1)
}

function Initials(val = '') {
  return val
    .split(' ')
    .map(p => p.substring(0, 1))
    .join('')
}

function MakeArray(val) {
  if ([undefined, null].includes(val)) return []
  return val instanceof Array ? val : [val]
}

function GetRouteBasedValue(config = {}) {
  return function() {
    let selector = MakeArray(config[this.$route.name] || config['Project'])
    let value = selector.map(s => this.$path(s, this.$store.state)).find(Boolean)

    return value || config.default
  }
}

const SortByKey = key => (a, b) => {
  if (a[key] < b[key]) return -1
  if (a[key] > b[key]) return 1
  return 0
}

export {
  GetRouteBasedValue,
  MakeArray,
  Clone,
  PartialUpdate,
  IconBinding,
  setByPath,
  getByPath,
  Debounce,
  isObject,
  getDimensions,
  getCookie,
  Capitalize,
  Initials,
  SortByKey,
}
