import API from '@/api/Contract'
import MixinFactory from '@/store/mixin'

const Mixin = MixinFactory()

const state = {
  ...Mixin.state,
}

const mutations = {
  ...Mixin.mutations,
}

const actions = {
  fetch: Mixin.actions.fetch(API.get),
  mutate: Mixin.actions.mutate(API.mutate),
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
