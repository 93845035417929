import { GraphQLClient } from 'graphql-request'

export default {
  async request(...args) {
    let headers = {}
    let params = new URLSearchParams(window.location.search)
    let accessToken = params.get('accessToken')
    if (accessToken) headers['Authorization'] = `Basic ${accessToken}`

    return new GraphQLClient(process.env.VUE_APP_API + '/graphql', { headers }).request(...args)
  },
}
