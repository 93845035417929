import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import RootApi from '@/api/Root'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    enums: {},
  },
  // mutations: {
  //   setEnums(state, payload) {
  //     state.enums = payload
  //   },
  // },
  // actions: {
  //   fetchEnums({ commit }) {
  //     RootApi.getEnums().then(data => commit('setEnums', data))
  //   },
  //   init({ dispatch }) {
  //     dispatch('fetchEnums')
  //   },
  // },
  modules,
})
