<template>
  <component :is="tag" v-bind="binding" v-on="$listeners" class="card">
    <div v-if="hasHeader" class="card__header" :style="headerStyleComp">
      <slot name="header" />
    </div>
    <div v-if="hasContent" class="card__content">
      <div v-if="title" class="card__title">{{ title }}</div>
      <slot />
    </div>
    <div v-if="hasFooter" class="card__footer">
      <div class="card__footer-content">
        <slot name="footer" />
      </div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    title: String,
    theme: {
      type: String,
      enums: ['default', 'border', 'flat'],
      default: 'default',
    },
    thumbnail: String,
    headerStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    binding() {
      return {
        ...this.$attrs,
        class: ['card--' + this.theme],
      }
    },
    hasHeader() {
      return this.$slots.header || this.thumbnail !== undefined
    },
    hasFooter() {
      return this.$slots.footer
    },
    hasContent() {
      return this.$slots.default
    },
    headerStyleComp() {
      let style = {
        ...this.headerStyle,
      }
      if (this.thumbnail) style.backgroundImage = `url(${this.thumbnail})`
      return style
    },
  },
}
</script>

<style lang="scss">
.card {
  --card-spacing: 1.75rem;
  $card-background: css-alpha('border-color', 0.2);

  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  background-color: white;
  text-decoration: none;
  color: inherit;

  &--default {
    box-shadow: $box-shadow;
  }

  &--border {
    border: 1px solid var(--border-color);
  }

  &--flat {
  }

  &__header {
    min-height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $card-background;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border-bottom: 2px solid $card-background;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:first-child:last-child {
      flex-grow: 1;
    }
  }

  &__title {
    font-weight: bold;
    line-height: 1;
    margin-bottom: 1rem;
  }

  &__content,
  &__footer,
  &__header {
    padding: var(--card-spacing);
  }

  &__content {
    flex-grow: 1;
  }

  &__footer {
    background-color: $card-background;
    border-top: 2px solid $card-background;

    &-content {
      display: flex;
      flex-wrap: wrap;

      @include gap(0.5rem);
    }
  }
}
</style>
