function Extractor(res) {
  let { errors, ...content } = res
  if (errors) throw errors
  let keys = Object.keys(content)
  return keys.length > 1 ? res : content[keys[0]]
}

function ResolveResource(value) {
  return value.split('/').pop().split('.').shift()
}

export { Extractor, ResolveResource }
