<template>
  <Modal :show.sync="show" class="task-modal">
    <Card theme="flat" tag="form" @submit.prevent="submit">
      <template #default>
        <InputComponent :label="$t('complaintTermsOfRequest')" :disabled="scoped" required>
          <textarea v-model="title" class="elder-input__element"></textarea>
        </InputComponent>
        <InputComponent :label="$t('description')" required>
          <textarea
            v-model="description"
            rows="3"
            class="elder-input__element"
            :placeholder="$t('typeHere') + '...'"
          ></textarea>
        </InputComponent>
        <FileComponent
          v-model="attachments"
          data-widget="attachment"
          :label="$tc('attachment', 2)"
          multiple
          :upload="upload"
        />
      </template>
      <template #footer>
        <ButtonComponent :label="$t('cancel')" @click="onClose" />
        <ButtonComponent
          type="submit"
          :promise="promise"
          :disabled="!validator.passes"
          :label="$t('send')"
          theme="primary"
          :icon="['fal', 'paper-plane']"
          @onSuccess="onSuccess"
        />
      </template>
    </Card>
  </Modal>
</template>

<script>
import Bus from '@/bus'
import { GetRouteBasedValue } from '@/utils'
import Validator from '@/mixins/validator'
import { VueComponentUpload as upload } from '@kvass/storage'

import { ModalComponent as Modal } from 'vue-elder-modal'
import { InputComponent } from 'vue-elder-input'
import Card from '@/components/Card'
import { FileComponent } from 'vue-elder-file'

export default {
  mixins: [Validator],
  data() {
    return {
      show: false,
      title: null,
      scoped: false,
      description: null,
      images: [],
      attachments: [],
      promise: null,
      validator: {
        rules: 'rules',
        data: 'dataComp',
      },
    }
  },
  computed: {
    project: GetRouteBasedValue({
      Bid: 'Residential.item.project',
      Purchase: 'Contract.item.reference.project',
      Shop: 'Contract.item.reference.project',
      default: null,
    }),
    residential: GetRouteBasedValue({
      Bid: 'Residential.item',
      Purchase: 'Contract.item.reference',
      Shop: 'Contract.item.reference',
      default: null,
    }),
    contract: GetRouteBasedValue({
      Purchase: 'Contract.item',
      Shop: 'Contract.item',
      Bid: 'Contract.item',
      default: null,
    }),
    dataComp() {
      return {
        title: this.title,
        description: this.description,
      }
    },
    rules() {
      return {
        title: 'required|string',
        description: 'required|string',
      }
    },
  },
  methods: {
    getToken() {
      return fetch('/api/shop/token', {
        headers: { Authorization: `Basic ${this.$route.query.accessToken}` },
      }).then(res => res.text())
    },
    submit() {
      this.promise = this.getToken().then(token =>
        fetch('/api/graphql', {
          method: 'POST',
          headers: {
            Authorization: `Kvass ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
          mutation($data: TaskCreate!) {
            TaskCreate(data: $data) {
              id
            }
          }`,
            variables: {
              data: {
                title: this.title,
                description: this.description,
                reference: { onModel: 'Shop', ref: this.residential.shop.id },
                attachments: this.attachments,
              },
            },
          }),
        }),
      )
    },
    reset() {
      this.title = null
      this.scoped = false
      this.description = null
      this.attachments = null
    },
    onClose() {
      if (this.scoped) this.reset()
      this.show = false
    },
    onSuccess() {
      this.reset()
      this.show = false
    },
    upload(file, onProgress, options) {
      return this.getToken().then(token =>
        upload(file, onProgress, { ...options, headers: { Authorization: `Kvass ${token}` } }),
      )
    },
  },
  created() {
    Bus.on('task:show', (data = {}) => {
      ;['title', 'description', 'images', 'attachments', 'scoped'].forEach(k => {
        if (!(k in data)) return
        this[k] = data[k]
      })

      this.show = true
    })
  },
  components: {
    Modal,
    Card,
    InputComponent,
    FileComponent,
  },
}
</script>

<style lang="scss">
.task-modal {
  z-index: get-layer('modal') + 3;

  .card {
    width: 100%;
    max-width: 600px;

    &__footer-content {
      justify-content: flex-end;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
</style>
